import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { loggedInGuard } from './core/guards/logged-in.guard';
import { workManagementRoutes } from './layouts/dashboard/modules/work-management/work-management.routes';
import { generalRoutes } from './layouts/dashboard/modules/general/general.routes';
import { residentManagementRoutes } from './layouts/dashboard/modules/resident-management/resident-management.routes';
import { staffManagementRoutes } from './layouts/dashboard/modules/staff-management/staff-management.routes';
import { securityOperationsRoutes } from './layouts/dashboard/modules/security-operations/security-operations.routes';
import { accountingRoutes } from './layouts/dashboard/modules/accounting/accounting.routes';

export const routes: Routes = [
    {
        path: '',
        canActivate: [loggedInGuard],
        loadComponent: () => import('./layouts/auth/auth.layout').then(c => c.AuthLayout),
        children: [
            {
                path: '',
                loadComponent: () => import('./layouts/auth/routes/login/login.component').then(c => c.LoginComponent)
            }
        ]
    },
    {
        path: 'invite/:id',
        loadComponent: () => import('./layouts/invite/invite.component').then(c => c.InviteComponent)
    },
    {
        path: 'dashboard',
        canActivate: [authGuard],
        loadComponent: () => import('./layouts/dashboard/dashboard.layout').then(c => c.DashboardLayout),
        children: [
            {
                path: '',
                loadComponent: () => import('./layouts/dashboard/modules/default-route/default-route.component').then(c => c.DefaultRouteComponent)
            },
            {
                path: 'work-management',
                children: workManagementRoutes,
            },
            {
                path: 'general',
                children: generalRoutes
            },
            {
                path: 'resident-management',
                children: residentManagementRoutes
            },
            {
                path: 'staff-management',
                children: staffManagementRoutes
            },
            {
                path: 'security-operations',
                children: securityOperationsRoutes
            },
            {
                path: 'accounting',
                children: accountingRoutes
            }
        ],
    },
    { path: '**', redirectTo: '' }
];