import { Routes } from "@angular/router";
import { CreateClusterComponent } from "./locations/routes/create-cluster/create-cluster.component";
import { MODULE_PERMISSIONS, ADVANCED_PERMISSIONS } from "../../../../core/models/enum";
import { permissionGuard } from "../../../../core/guards/permissions.guard";

export const workManagementRoutes: Routes = [
    {
        path: 'work-packages',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.WORK_PACKAGE, permissionLevel: ADVANCED_PERMISSIONS.VIEW_WORK_PACKAGE, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./work-packages/work-packages.component').then(c => c.WorkPackagesComponent)
            },
            {
                path: 'create-work-package',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.WORK_PACKAGE, permissionLevel: ADVANCED_PERMISSIONS.ADD_WORK_PACKAGE, fallBackUrl: 'dashboard/work-management/work-packages' },
                loadComponent: () => import('./work-packages/routes/create-work-package/create-work-package.component').then(c => c.CreateWorkPackageComponent)
            },
            {
                path: 'edit-work-package/:id',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.WORK_PACKAGE, permissionLevel: ADVANCED_PERMISSIONS.EDIT_WORK_PACKAGE, fallBackUrl: 'dashboard/work-management/work-packages' },
                loadComponent: () => import('./work-packages/routes/create-work-package/create-work-package.component').then(c => c.CreateWorkPackageComponent)
            },
            {
                path: 'copy-work-package/:copyId',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.WORK_PACKAGE, permissionLevel: ADVANCED_PERMISSIONS.DUPLICATE_WORK_PACKAGE, fallBackUrl: 'dashboard/work-management/work-packages' },
                loadComponent: () => import('./work-packages/routes/create-work-package/create-work-package.component').then(c => c.CreateWorkPackageComponent)
            },
            {
                path: 'view-work-package/:id',
                loadComponent: () => import('./work-packages/routes/view-work-package/view-work-package.component').then(c => c.ViewWorkPackageComponent)
            }
        ],
    },
    {
        path: 'assets-management',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.ASSETS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_ASSETS, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./assets-management/assets-management.component').then(c => c.AssetsManagementComponent)
            },
            {
                path: 'add-asset',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.ASSETS, permissionLevel: ADVANCED_PERMISSIONS.ADD_ASSETS, fallBackUrl: 'dashboard/work-management/assets-management' },
                loadComponent: () => import('./assets-management/routes/add-asset/add-asset.component').then(c => c.AddAssetComponent)
            },
            {
                path: 'edit-asset/:id',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.ASSETS, permissionLevel: ADVANCED_PERMISSIONS.EDIT_ASSET_DETAILS, fallBackUrl: 'dashboard/work-management/assets-management' },
                loadComponent: () => import('./assets-management/routes/add-asset/add-asset.component').then(c => c.AddAssetComponent)
            },
            {
                path: 'view-asset/:id',
                loadComponent: () => import('./assets-management/routes/view-asset/view-asset.component').then(c => c.ViewAssetComponent)
            },
        ]
    },
    {
        path: 'work-logs',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.WORKLOGS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_WORK, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./work-logs/work-logs.component').then(c => c.WorkLogsComponent)
            },
            {
                path: 'view-work-log/:id',
                loadComponent: () => import('./work-logs/routes/view-work-log/view-work-log.component').then(c => c.ViewWorkLogComponent)
            },
        ],
    },
    {
        path: 'locations',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.LOCATIONS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_LOCATIONS, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./locations/locations.component').then(c => c.LocationsComponent)
            },
            {
                path: 'view-cluster/:id',
                loadComponent: () => import('./locations/routes/manage-cluster/manage-cluster.component').then(c => c.ManageClusterComponent)
            },
            {
                path: 'edit-cluster/:id',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.LOCATIONS, permissionLevel: ADVANCED_PERMISSIONS.EDIT_CLUSTER, fallBackUrl: 'dashboard/work-management/locations' },
                loadComponent: () => import('./locations/routes/manage-cluster/manage-cluster.component').then(c => c.ManageClusterComponent)
            },
            {
                path: 'create-cluster',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.LOCATIONS, permissionLevel: ADVANCED_PERMISSIONS.CREATE_CLUSTER, fallBackUrl: 'dashboard/work-management/locations' },
                loadComponent: () => import('./locations/routes/create-cluster/create-cluster.component').then(c => CreateClusterComponent)
            },
        ],
    },
    {
        path: 'work-scheduler',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.WORK_SCHEDULER, permissionLevel: ADVANCED_PERMISSIONS.VIEW_WORK_SCHEDULE, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./work-scheduler/work-scheduler.component').then(c => c.WorkSchedulerComponent)
            },
            {
                path: 'scheduler/:id',
                loadComponent: () => import('./work-scheduler/routes/view-scheduler/view-scheduler.component').then(c => c.ViewSchedulerComponent)
            },
            {
                path: 'add-scheduler',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.WORK_SCHEDULER, permissionLevel: ADVANCED_PERMISSIONS.ADD_WORK_SCHEDULE, fallBackUrl: 'dashboard/work-management/work-scheduler' },
                loadComponent: () => import('./work-scheduler/routes/add-scheduler/add-scheduler.component').then(c => c.AddSchedulerComponent)
            },
            {
                path: 'edit-scheduler/:id',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.WORK_SCHEDULER, permissionLevel: ADVANCED_PERMISSIONS.EDIT_WORK_SCHEDULE, fallBackUrl: 'dashboard/work-management/work-scheduler' },
                loadComponent: () => import('./work-scheduler/routes/add-scheduler/add-scheduler.component').then(c => c.AddSchedulerComponent)
            }
        ]
    },
    {
        path: 'issues',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.ISSUES, permissionLevel: ADVANCED_PERMISSIONS.VIEW_ISSUES, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./issues/issues.component').then(c => c.IssuesComponent)
            },
            {
                path: ':id',
                loadComponent: () => import('./issues/routes/issue-details/issue-details.component').then(c => c.IssueDetailsComponent)
            },
        ]
    }
]