import { Routes } from "@angular/router";
import { permissionGuard } from "../../../../core/guards/permissions.guard";
import { MODULE_PERMISSIONS, ADVANCED_PERMISSIONS } from "../../../../core/models/enum";
export const accountingRoutes: Routes = [
    {
        path:'headers',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.HEADERS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_HEADERS, fallBackUrl: 'dashboard' },
        loadComponent: () => import('./headers/headers.component').then(c => c.HeadersComponent)
    },
 
]