import { Routes } from "@angular/router";
import { permissionGuard } from "../../../../core/guards/permissions.guard";
import { MODULE_PERMISSIONS, ADVANCED_PERMISSIONS } from "../../../../core/models/enum";

export const generalRoutes: Routes = [
    {
        path: 'quick-contacts',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.QUICK_CONTACTS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_CONTACTS, fallBackUrl: 'dashboard' },
        loadComponent: () => import('./quick-contacts/quick-contacts.component').then(c => c.QuickContactsComponent)
    },
    {
        path: 'communications',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.COMMUNICATIONS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_NOTICES, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./communications/communications.component').then(c => c.CommunicationsComponent)
            },
            {
                path: 'post-notice',
                loadComponent: () => import('./communications/routes/post-notice/post-notice.component').then(c => c.PostNoticeComponent)
            },
            {
                path: 'duplicate-notice/:id',
                loadComponent: () => import('./communications/routes/post-notice/post-notice.component').then(c => c.PostNoticeComponent)
            },
            {
                path: 'view-notice/:id',
                loadComponent: () => import('./communications/routes/view-notice/view-notice.component').then(c => c.ViewNoticeComponent)
            }
        ]
    }
]