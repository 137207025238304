import { Routes } from "@angular/router";
import { permissionGuard } from "../../../../core/guards/permissions.guard";
import { MODULE_PERMISSIONS, ADVANCED_PERMISSIONS } from "../../../../core/models/enum";

export const residentManagementRoutes: Routes = [
    {
        path: 'renovation-works',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.RENOVATION_WORKS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_INTERIOR_WORK_AND_RENOVATION, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./renovation-works/renovation-works.component').then(c => c.RenovationWorksComponent),
            },
            {
                path: 'view-renovation/:id',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.RENOVATION_WORKS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_INTERIOR_WORK_AND_RENOVATION, fallBackUrl: 'dashboard/resident-management/renovation-works' },
                loadComponent: () => import('./renovation-works/routes/view-renovation/view-renovation.component').then(c => c.ViewRenovationComponent)
            },
            {
                path: 'add-renovation',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.RENOVATION_WORKS, permissionLevel: ADVANCED_PERMISSIONS.ADD_RENOVATION_REQUEST, fallBackUrl: 'dashboard/resident-management/renovation-works' },
                loadComponent: () => import('./renovation-works/routes/add-renovation/add-renovation.component').then(c => c.AddRenovationComponent)
            },
            {
                path: 'edit-renovation/:id',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.RENOVATION_WORKS, permissionLevel: ADVANCED_PERMISSIONS.ADD_RENOVATION_REQUEST, fallBackUrl: 'dashboard/resident-management/renovation-works' },
                loadComponent: () => import('./renovation-works/routes/add-renovation/add-renovation.component').then(c => c.AddRenovationComponent)
            },
        ]
    },
    {
        path: 'violation-setup',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.VIOLATION_SETUP, permissionLevel: ADVANCED_PERMISSIONS.VIEW_VIOLATIONS, fallBackUrl: 'dashboard' },
        loadComponent: () => import('./violation-setup/violation-setup.component').then(c => c.ViolationSetupComponent)
    },
    {
        path: 'violation-tickets',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.ALL_VIOLAIONS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_VIOLATIONS, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./violation-tickets/violation-tickets.component').then(c => c.ViolationTicketsComponent),

            },
            {
                path: 'violation-details/:id',
                loadComponent: () => import('./violation-tickets/routes/violation-details/violation-details.component').then(c => c.ViolationDetailsComponent)
            },
        ]
    },
    {
        path: 'helpdesk-setup',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.HELPDESK_SETUP, permissionLevel: ADVANCED_PERMISSIONS.VIEW_CATEGORIES, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./helpdesk-setup/helpdesk-setup.component').then(c => c.HelpdeskSetupComponent)

            },
            {
                path: 'helpdesk-settings',
                loadComponent: () => import('./helpdesk-setup/routes/helpdesk-settings/helpdesk-settings.component').then(c => c.HelpdeskSettingsComponent)
            }
        ]
    },
    {
        path: 'helpdesk-tickets',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.HELPDESK_TICKETS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_TICKETS, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./helpdesk-tickets/helpdesk-tickets.component').then(c => c.HelpdeskTicketsComponent),

            },
            {
                path: 'helpdesk-details/:id',
                loadComponent: () => import('./helpdesk-tickets/routes/ticket/ticket.component').then(c => c.TicketComponent)
            }
        ]
    },
    {
        path: 'flats',
        canActivate: [permissionGuard],
        data: { module: MODULE_PERMISSIONS.FLATS, permissionLevel: ADVANCED_PERMISSIONS.VIEW_FLATS, fallBackUrl: 'dashboard' },
        children: [
            {
                path: '',
                loadComponent: () => import('./flats/flats.component').then(c => c.FlatsComponent),
            },
            {
                path: 'view-flat/:id',
                loadComponent: () => import('./flats/routes/view-flat/view-flat.component').then(c => c.ViewFlatComponent),
            },
            {
                path: 'activate/:id',
                canActivate: [permissionGuard],
                data: { module: MODULE_PERMISSIONS.FLATS, permissionLevel: ADVANCED_PERMISSIONS.ACTIVATE_FLATS, fallBackUrl: 'dashboard/resident-management/flats' },
                loadComponent: () => import('./flats/routes/activate-flat/activate-flat.component').then(c => c.ActivateFlatComponent)
            }
        ]
    }
];